.print-container {
    margin: 10px 10px 10px 10px;
}

.print-field {
    margin-right: 10px;
    line-height: 25px;
}

body {
    background-color: white !important;
}

.print-table {
    width: 100%;
    border: 1px solid;
    border-collapse: collapse;
}

.print-table th,
.print-table td {
    border: 1px solid;
    padding: 5px 8px 5px 8px;
}