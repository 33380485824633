.primary-text {
    font-size: 15px;
    font-weight: bold;
}

.normal-text {
    font-size: 13px;

}

.form-item-row .arco-form-item {
    margin-bottom: 0;
}

.form-item-row .arco-space-item {
    margin-bottom: 3px;
}

.form-searchbar .arco-form-layout-inline {
    margin-right: 3px;
}

.form-searchbar .arco-form-item {
    margin-bottom: 0;
}

.top-left-menu {
    font-size: 16px;
    font-weight: bold;
}
