.header {
    z-index: 100;
    width: 100%;
    position: fixed;
    top: 0;
    background: linear-gradient(163.85deg, #1d2129 0%, #00308f 100%);
}

.header .arco-menu-dark {
    background: linear-gradient(163.85deg, #1d2129 0%, #00308f 100%);
}

.header .arco-menu-item {
    color: var(--color-text-3);
    background: transparent;
}


.logo {
    /* width: 180px; */
}

.logo-pic {
    position: absolute;
    height: 32px;
    width:  32px;
}

.logo-text {
    margin-left: 38px;
    font-size: 20px !important;
    color: var(--color-fill-1);
    font-weight: bold;
}

.profile {
    position: fixed;
    top: 12px;
    right: 22px;
}

.default-avatar {
    background-color: var(--color-fill-1);
    cursor: pointer;
}

.default-avatar .arco-avatar-text {
    color: var(--color-text-1);
}

.dropdown-icon {
    margin-right: 8px;
}
