.home-page {
  /* background-image: url("../../assets/HomePage.jpeg");
  background-size: cover;
  background-repeat: no-repeat; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 60px;
}

.home-button {
  width: 280px;
  height: 65px;
  font-size: 20px;
  border-width: 3px !important;
}