.pricing-header {
    text-align: center;
}

.pricing-content {
    padding-top: 20px;
}

.pricing-content, li {
    height: 35px;
}