.login-content-container {
    background: var(--color-bg-2);
    height: 100vh;
    width: 100%;
    position: absolute;
}

.login-content {
    display: flex;
    flex: 1;
    position: relative;
    padding-bottom: 40px;
    padding-top: 100px;
    justify-content: center;
    align-items: center;
}

.login-form-wrapper {
    width: 320px;
}

.login-form-title {
    font-size: 28px;
    font-weight: 500;
    color: var(--color-text-1);
    line-height: 32px;
}

.login-form-sub-title {
    font-size: 20px;
    line-height: 24px;
    color: var(--color-text-3);
}

.login-form-error-msg {
    height: 32px;
    line-height: 32px;
    color: rgb(var(--red-6));
}

.login-form-password-actions {
    display: flex;
    justify-content: space-between;
}

.login-form-register-btn {
    color: var(--color-text-3) !important;
}

.login-footer {
    position: absolute;
    font-size: 14px;
    font-weight: var(--font-weight-400);
    text-align: center;
    height: 40px;
    width: 100%;
    bottom: 0;
    right: 0;
    padding-top: 50px;
    padding-bottom: 8px;
}