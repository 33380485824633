.App {
  /* text-align: center; */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.layout-sider {
  position: fixed !important;
  height: 100%;
  width: 220px !important;
}

.layout-content {
  width: 100%;
  height: 100%;
  min-width: 1100px;
}

.layout-content-wrapper {
  padding: 16px 20px 80px 20px;
}

.content-breadcrumb {
  padding-bottom: 16px;
}

.arco-card {
  background: var(--color-bg-2);
  transition: box-shadow 0.2s cubic-bezier(0, 0, 1, 1);
  border-radius: var(--border-radius-medium) !important;
}

/* input.arco-input-disabled {
  color: var(--color-text-1);
  -webkit-text-fill-color: var(--color-text-1);
}

.arco-select-disabled {
  color: var(--color-text-1);
  -webkit-text-fill-color: var(--color-text-1);
}

input::input-placeholder {
  color: var(--color-text-3) !important;
}

input::-webkit-input-placeholder {
  color: var(--color-text-3) !important;
} */

.rotated90 {
  transform: rotate(90deg);
}

.arco-select-popup .arco-select-option {
  padding: 0 12px 0 22px !important;
}

/* .arco-picker-disabled input[disabled] {
  color: var(--color-text-3) !important;
  -webkit-text-fill-color: var(--color-text-3) !important;
} */

.arco-typography {
  word-wrap: break-word !important;
  word-break: keep-all !important;
}

.alert-bottom {
  position: absolute;
  width: calc(100% - 260px) !important;
  bottom: 0;
}