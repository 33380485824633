.desc-summary {
    font-weight: bold;
    color: var(--color-text-1);
}

.tree-node-title {
    font-weight: bold;
}

.tree-node-label-1 {
    padding-left: 20px;
}

.tree-node-label-2 {
    padding-left: 40px;
}

.tree-node-label-3 {
    padding-left: 60px;
}

.tree-node-label-4 {
    padding-left: 80px;
}

.tree-node-label-summary-1 {
    padding-left: 20px;
    font-weight: bold;
}

.tree-node-label-summary-2 {
    padding-left: 40px;
    font-weight: bold;
}

.tree-node-label-summary-3 {
    padding-left: 60px;
    font-weight: bold;
}

.tree-node-label-summary-4 {
    padding-left: 80px;
    font-weight: bold;
}

.tree-node-value {
    padding-left: 200px;
}

.tree-node-value-summary {
    padding-left: 200px;
    font-weight: bold;
}

.tree-node-value .arco-link {
    padding-left: 0;
}